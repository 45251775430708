import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Parallelogram, ParallelogramExpMetal} from '../../../../model/basic-templates.model';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {UserTemplate} from '../../../../model/user-template.model';
import {select, Store} from '@ngrx/store';
import {
  getParallelogramTemplate,
  getUserTemplates,
  MevacoState,
  ProductConfigurationAddElement, reqValParallelogramExpMetal
} from '../../../../store';
import {TemplateUtils} from '../../templatesUtils';
import {GetUserTemplates} from '../../../../store/actions/user-template.actions';
import {map} from 'rxjs/operators';
import {aabbOfPolyline, PolylineArea, Segment, SegmentType} from 'webcad/models';
import {ShapeWithHoles, shapeWithHolesHash} from '../../../../model/shape-with-holes';
import {LineIntersect2D, normalizeVector2, subVectors2, Vector2} from 'webcad/math';
import {PerforationAreaModel} from '../../../../model/perforation-area.model';
import {Element, ElementType} from '../../../../model/product-configuration/element.model';
import {TemplateName} from '../../template-name';
import {Guid} from 'guid-typescript';
import {
  SetParallelogramA, SetParallelogramB1, SetParallelogramExpMetal,
  SetParallelogramHa, SetParallelogramTemplate,
  SetParallelogramTilt
} from '../../../../store/actions/element-creator.actions';
import {roundDecimal} from '../../../../utils/utils';

@Component({
  selector: 'mevaco-parallelogram-expanded-metal',
  templateUrl: './parallelogram-expanded-metal.component.html',
  styleUrls: ['./parallelogram-expanded-metal.component.css']
})
export class ParallelogramExpandedMetalComponent implements OnInit {
  @ViewChild('closeButton', { static: true })
  closeButton: ElementRef;
  @Output() dismissModal = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() isModalOpen = new EventEmitter();

  public tiltedTemplate: ParallelogramExpMetal;
  public templates: Observable<UserTemplate[]>;
  public pi = Math.PI;
  public roundDecimal = roundDecimal;
  private subscriptions: Subscription[] = [];
  public reqValParallelogram: Observable<boolean>;

  constructor(
    private store: Store<MevacoState>,
    public templateUtils: TemplateUtils
  ) {}

  ngOnInit() {
    this.store.dispatch(new GetUserTemplates());
    this.templates = this.store.pipe(select(getUserTemplates));
    this.subscriptions.push(
      this.store
        .pipe(select(getParallelogramTemplate))
        .subscribe((v) => (this.tiltedTemplate = { ...v }))
    );
    this.reqValParallelogram = this.store.pipe(select(reqValParallelogramExpMetal));
    this.createFromTiltedPlate();
  }

  isModalOpenMethod() {
    this.isModalOpen.emit(false);
  }

  dismiss(str: string) {
    this.dismissModal.emit(str);
  }

  close(input: {}) {
    this.closeModal.emit(input);
  }

  createFromTiltedPlate() {
    const obs = fromEvent(this.closeButton.nativeElement, 'click').pipe(
      map(() => {
        return this.tiltedTemplate;
      })
    );

    obs.subscribe(
      (result) => {
        const plateA = result.a / 1000;
        const plateB = result.b / 1000;
        const b1 = result.b1 / 1000;
        const conture: Segment[] = [
          {
            type: SegmentType.line,
            begin: { x: 0, y: 0 },
            end: { x: plateB, y: 0 },
          },
          {
            type: SegmentType.line,
            begin: { x: plateB, y: 0 },
            end: { x: plateB + b1, y: plateA },
          },
          {
            type: SegmentType.line,
            begin: { x: plateB + b1, y: plateA },
            end: { x: b1, y: plateA },
          },
          {
            type: SegmentType.line,
            begin: { x: b1, y: plateA },
            end: { x: 0, y: 0 },
          },
        ];
        const shapeWithHoles: ShapeWithHoles = {
          conture: conture,
          holes: [],
        };
        shapeWithHoles.aabb = aabbOfPolyline(shapeWithHoles.conture);
        shapeWithHoles.area = PolylineArea(shapeWithHoles.conture);
        shapeWithHoles.hash = shapeWithHolesHash(shapeWithHoles);
        const dir: Vector2 = normalizeVector2(
          subVectors2({ x: b1, y: plateA }, { x: 0, y: 0 })
        );
        const element: Element = {
          type: ElementType.individual,
          templateName: TemplateName.expandedMetalParallelogram,
          boundary: null,
          position: -1, // will be set inb reducer
          quantity: 1,
          a: plateA,
          b: plateB,
          aIst: plateA,
          bIst: plateB,
          e1: '',
          e1ist: '',
          e2: '',
          e2ist: '',
          f1: '',
          f1ist: '',
          f2: '',
          f2ist: '',
          openMeshE: '',
          openMeshF: '',
          toleranceWidth: 0,
          toleranceLength: 0,
          label: '',
          note: '',
          unperforated: false,
          posibleCoil: 'No',
          posiblePlate: 'No',
          shapes: null,
          nodes: null,
          verticesIndexes: null,
          visualizationShape: null,
          previewImageId: Guid.create().toString(),
          shape: shapeWithHoles,
          cutShape: shapeWithHoles,
          perforationAreas: [],
          helpLines: [],
          measurements: [],
          angleMeasurements: [],
          mountings: [],
          perforationAutoCenter: true,
          area: shapeWithHoles.area,
          minRadius: null,
          minParallelEdgesDistance: null,
          minMarginDistance: null,
          isPerforationSimpleRect: null,
          isShapeSimpleRect: null,
          minMountingHoleEdgeDistance: null,
          minMountingHolesDistance: null,
          minOutsideAngle: null,
          numberOfArcs: null,
          minDistanceBetweenEdges: null,
          minDistanceBetweenMountingAndPerforatedArea: null,
          // perforation: null,
          possibleAllAcross: true,
          minDistanceBetweenPerforationAreas: null,
          lfbIst: undefined,
          lflIst: undefined,

          breakLines: [],
          bendingLinesDistances: [],
          // possiblePerforationZones: []
        };
        this.store.dispatch(new ProductConfigurationAddElement(element));
      },
      (reason) => {}
    );
  }

  setTiltedPlateAngle(value: any) {
    this.store.dispatch(new SetParallelogramTilt(value));
  }

  setTiltedPlateA(value: any) {
    this.store.dispatch(new SetParallelogramA(value));
  }

  setTiltedPlateHa(newHA: any) {
    this.store.dispatch(new SetParallelogramHa(newHA));
  }

  setTiltedPlateB1(newB1: any) {
    this.store.dispatch(new SetParallelogramB1(newB1));
  }

  setParallelogramExpMetal(template: ParallelogramExpMetal) {
    this.store.dispatch(new SetParallelogramExpMetal({ ...template }));
  }

}
