import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Trapeze, TrapezeExpandedMetal} from '../../../../model/basic-templates.model';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {UserTemplate} from '../../../../model/user-template.model';
import {select, Store} from '@ngrx/store';
import {getTrapezeTemplate, getUserTemplates, MevacoState, ProductConfigurationAddElement} from '../../../../store';
import {TemplateUtils} from '../../templatesUtils';
import {GetUserTemplates} from '../../../../store/actions/user-template.actions';
import {map} from 'rxjs/operators';
import {LineIntersect2D, normalizeVector2, subVectors2, Vector2} from 'webcad/math';
import {aabbOfPolyline, LineSegmentsFromPath, PolylineArea} from 'webcad/models';
import {ShapeWithHoles, shapeWithHolesHash} from '../../../../model/shape-with-holes';
import {PerforationAreaModel} from '../../../../model/perforation-area.model';
import {Element, ElementType} from '../../../../model/product-configuration/element.model';
import {TemplateName} from '../../template-name';
import {Guid} from 'guid-typescript';
import {
  SetTrapezeA,
  SetTrapezeAlpha,
  SetTrapezeB1, SetTrapezeB2, SetTrapezeBeta, SetTrapezeHA1, SetTrapezeHA2, SetTrapezeHB,
  SetTrapezeTemplate
} from '../../../../store/actions/element-creator.actions';
import { reqValTrapezeExpMetal } from '../../../../store';

@Component({
  selector: 'mevaco-trapez-expanded-metal',
  templateUrl: './trapez-expanded-metal.component.html',
  styleUrls: ['./trapez-expanded-metal.component.css']
})
export class TrapezExpandedMetalComponent implements OnInit {
  @Output() isModalOpen = new EventEmitter();
  @Output() dismissModal = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @ViewChild('closeButton', { static: true })
  closeButton: ElementRef;

  public trapezeTemplate: TrapezeExpandedMetal;
  public templates: Observable<UserTemplate[]>;
  public pi = Math.PI;
  public reqValTrapeze: Observable<boolean>;
  public minf = Math.min;

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<MevacoState>,
    public templateUtils: TemplateUtils
  ) {}

  ngOnInit() {
    this.createFromTrapezeTemplate();
    this.store.dispatch(new GetUserTemplates());
    this.templates = this.store.pipe(select(getUserTemplates));
    this.subscriptions.push(
      this.store
        .pipe(select(getTrapezeTemplate))
        .subscribe((v) => (this.trapezeTemplate = { ...v }))
    );
    this.reqValTrapeze = this.store.pipe(select(reqValTrapezeExpMetal));
  }

  isModalOpenMethod() {
    this.isModalOpen.emit(false);
  }

  dismiss(str: string) {
    this.dismissModal.emit(str);
  }

  close(input: {}) {
    this.closeModal.emit(input);
  }

  createFromTrapezeTemplate() {
    const obs = fromEvent(this.closeButton.nativeElement, 'click').pipe(
      map(() => {
        return this.trapezeTemplate;
      })
    );

    obs.subscribe((result) => {
      const ha1 = result.ha1 / 1000;
      const ha2 = result.ha2 / 1000;
      const a = result.a / 1000;
      const b1 = result.b1 / 1000;
      const b2 = result.b2 / 1000;
      const alpha = result.alpha;
      const beta = result.beta;
      const hb = result.hb / 1000;
      const path: Vector2[] = [
        { x: 0, y: 0 },
        { x: b1, y: 0 },
        { x: hb + b2, y: a },
        { x: hb, y: a },
      ];
      const conture = LineSegmentsFromPath(path);
      const shapeWithHoles: ShapeWithHoles = {
        holes: [],
        conture: conture,
      };
      shapeWithHoles.aabb = aabbOfPolyline(shapeWithHoles.conture);
      shapeWithHoles.area = PolylineArea(shapeWithHoles.conture);
      shapeWithHoles.hash = shapeWithHolesHash(shapeWithHoles);
      const dirA: Vector2 = normalizeVector2(
        subVectors2({ x: hb, y: a }, { x: 0, y: 0 })
      );
      const dirB: Vector2 = normalizeVector2(
        subVectors2({ x: hb + b2, y: a }, { x: b1, y: 0 })
      );
      const xAsix: Vector2 = { x: 1, y: 0 };
      const element: Element = {
        type: ElementType.individual,
        templateName: TemplateName.expandedMetalTrapeze,
        boundary: null,
        position: -1, // will be set inb reducer
        quantity: 1,
        a: a,
        b: b1,
        aIst: a,
        bIst: b1,
        e1: '',
        e1ist: '',
        e2: '',
        e2ist: '',
        f1: '',
        f1ist: '',
        f2: '',
        f2ist: '',
        openMeshE: '',
        openMeshF: '',
        toleranceWidth: 0,
        toleranceLength: 0,
        label: '',
        note: '',
        unperforated: false,
        posibleCoil: 'No',
        posiblePlate: 'No',
        shapes: null,
        nodes: null,
        verticesIndexes: null,
        visualizationShape: null,
        previewImageId: Guid.create().toString(),
        shape: shapeWithHoles,
        cutShape: shapeWithHoles,
        perforationAreas: [],
        helpLines: [],
        measurements: [],
        angleMeasurements: [],
        mountings: [],
        perforationAutoCenter: true,
        area: shapeWithHoles.area,
        minRadius: null,
        minParallelEdgesDistance: null,
        minMarginDistance: null,
        isPerforationSimpleRect: null,
        isShapeSimpleRect: null,
        minMountingHoleEdgeDistance: null,
        minMountingHolesDistance: null,
        minOutsideAngle: null,
        numberOfArcs: null,
        minDistanceBetweenEdges: null,
        minDistanceBetweenMountingAndPerforatedArea: null,
        // perforation: null,
        possibleAllAcross: true,
        minDistanceBetweenPerforationAreas: null,
        lfbIst: undefined,
        lflIst: undefined,

        breakLines: [],
        bendingLinesDistances: [],
        // possiblePerforationZones: []
      };
      this.store.dispatch(new ProductConfigurationAddElement(element));
    });
  }

  // setTrapezeTemplate(template: Trapeze, attribute: string, value: any) {
  //   if (isNaN(value) || isNaN(parseFloat(value))) {
  //     template[attribute] = null;
  //   } else {
  //     template[attribute] = Number(value);
  //   }
  //   this.store.dispatch(new SetTrapezeTemplate({ ...template }));
  // }

  setTrapezeA(value: any) {
    this.store.dispatch(new SetTrapezeA(value));
  }

  setTrapezeAlpha(value: any) {
    this.store.dispatch(new SetTrapezeAlpha(value));
  }

  setTrapezeB1(value: any) {
    this.store.dispatch(new SetTrapezeB1(value));
  }

  setTrapezeB2(value: any) {
    this.store.dispatch(new SetTrapezeB2(value));
  }

  setTrapezeBeta(value: any) {
    this.store.dispatch(new SetTrapezeBeta(value));
  }

  setTrapezeHA1(value: any) {
    this.store.dispatch(new SetTrapezeHA1(value));
  }

  setTrapezeHA2(value: any) {
    this.store.dispatch(new SetTrapezeHA2(value));
  }

  setTrapezeHB(value: any) {
    this.store.dispatch(new SetTrapezeHB(value));
  }

}
