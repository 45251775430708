import { ErrorHandler, Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  of,
} from "rxjs";
import { filter, take } from "rxjs/operators";
import { ButtonType, PointerEventType, PointerState } from "webcad/collision";
import { Vector2 } from "webcad/math";
import { SegmentAndProjectedPoint } from "webcad/models";
import {ProductType, Step} from "../model/product-configuration/product-configuration.model";
import { ActionType } from "../model/shape-action.model";
import { PerforationService } from "../services/perforation.service";
import {
  MevacoState,
  getClosestSegmentVisibilityState,
  getPlate,
  getSnapOptions,
  getStep, getProductType,
} from "../store/reducers";
import { DrawingTool } from "../tools/drawing-tool.interface";
import { BowTool } from "../tools/drawingTools/bow.tool";
import { CassettePerforationCreatorTool } from "../tools/drawingTools/cassette-perforation-creator.tool";
import { CircleTool } from "../tools/drawingTools/circle.tool";
import { LineMainTool } from "../tools/drawingTools/line/line-main.tool";
import { AssistedMountingTool } from "../tools/drawingTools/mountings/assisted-mounting.tool";
import { MountingTool } from "../tools/drawingTools/mountings/mounting.tool";
import { PerforationAdjustmentTool } from "../tools/drawingTools/perforation-adjustment.tool";
import { RectangleTool } from "../tools/drawingTools/rectangle/rectangle.tool";
import { WallpaperMoveTool } from "../tools/drawingTools/wallpaper-move.tool";
import { AngleMeasurementTool } from "../tools/editTools/angle-measurement.tool";
import { HelpLineTool } from "../tools/editTools/help-line.tool";
import { AttachmentImportTool } from "../tools/editTools/import/attachment-import.tool";
import { ImportSelectTool } from "../tools/editTools/import/import-select.tool";
import { OutlinePerforationImportTool } from "../tools/editTools/import/outline-perforation-import.tool";
import { SelectPerforationImportTool } from "../tools/editTools/import/select-perforation-import.tool";
import { SelectPerforationTool } from "../tools/editTools/import/select-perforation.tool";
import { MeasurementTool } from "../tools/editTools/measurement.tool";
import { RotationTool } from "../tools/editTools/rotation.tool";
import { SelectTool } from "../tools/editTools/select.tool";
import { TreatCornerTool } from "../tools/editTools/treat-corner.tool";
import { PerforationModeTool } from "../tools/perforation-mode.tool";
import { ExpandedMetalModeTool } from "../tools/expanded-metal-mode.tool";
import { Tool } from "../tools/tool.interface";
import { DragArrowsManagerProvider } from "./drag-arrows-manager.provider";
import { MeasurementsManagerProvider } from "./measurements-manager.provider";
import { MevacoPointerProvider } from "./mevaco-pointer.provider";
import { PointerMeshProvider } from "./pointer-mesh.provider";
import { SceneProvider } from "./scene.provider";
import { ShapeWithHolesProvider } from "./shape-with-holes.provider";
import { TranslationProvider } from "./translation.provider";
import { View3dProvider } from "./view3d.provider";
import { WebcadProvider } from "./webcad.provider";
import {
  EventState,
  KeyboardEventTypes,
  KeyboardInfo,
  KeyboardInfoPre,
  Nullable,
  Observer,
  Scene
} from "@babylonjs/core";
import {Actions} from "@ngrx/effects";

export enum ToolControlType {
  actionButton = "actionButton",
  input = "input",
}

export interface ToolControl {
  type: ToolControlType;
}

export interface ActionButton extends ToolControl {
  name: string;
  action: () => void;
  enable?: Observable<boolean>;
  active?: Observable<boolean>;
}

export interface ToolInput extends ToolControl {
  min: number;
  max: number;
  decimals: number;
  setVale: (value: number) => void;
}

export interface NamedTool {
  tool: Tool;
  name: string;
  toolTip?: string;
  hidden?: Observable<boolean>;
  controls?: ActionButton[] | ToolInput[];
}

@Injectable()
export class ToolProvider {
  private activeTool: Subject<Tool>;
  private activeToolObject: Tool;
  private onClosestSegmentChangedSub: Subscription;
  private keyboardSub: Nullable<
    Observer<KeyboardInfoPre>
  >;
  private scene: Scene;
  private state: boolean;
  private latestPointerState: PointerState;
  protected polylinetool: LineMainTool;
  protected circletool: CircleTool;
  protected rectangleTool: RectangleTool;
  protected _selectTool: SelectTool;
  protected bowTool: BowTool;
  private measurementTool: MeasurementTool;
  private angleMeasurementTool: AngleMeasurementTool;
  private drawingTools: BehaviorSubject<NamedTool[]>;
  private utilTools: BehaviorSubject<NamedTool[]>;
  private measureTools: BehaviorSubject<NamedTool[]>;
  private contureTools: BehaviorSubject<NamedTool[]>;
  private eckpunkteTools: BehaviorSubject<NamedTool[]>;
  private _mountingTool: MountingTool;
  private _assistedMountingTool: AssistedMountingTool;
  private _perforationAdjustmentTool: PerforationAdjustmentTool;
  private _wallpapperMoveTool: WallpaperMoveTool;
  private _importTool: ImportSelectTool;
  private _selectPerforation: SelectPerforationTool;
  private rotationTool: RotationTool;
  private _attachmentImportTool: AttachmentImportTool;
  private pointerMeshProvider: PointerMeshProvider;
  private _treatCornerTool: TreatCornerTool;
  private outlinePerforationImportTool: OutlinePerforationImportTool;
  private _selectPerforationImportTool: SelectPerforationImportTool;
  private _helpLineTool: HelpLineTool;
  private _perforationModeTool: PerforationModeTool;
  private _expandedMetalModeTool: ExpandedMetalModeTool;
  private _cassettePerforationTool: CassettePerforationCreatorTool;

  constructor(
    private view3dProvider: View3dProvider,
    private pointerController: MevacoPointerProvider,
    private store: Store<MevacoState>,
    private actions: Actions,
    private shapeWithHolesProvider: ShapeWithHolesProvider,
    private sceneProvider: SceneProvider,
    private translationProvider: TranslationProvider,
    private webcadProvider: WebcadProvider,
    private measurementsManagerProvider: MeasurementsManagerProvider,
    private dragArrowsMananagerProvider: DragArrowsManagerProvider,
    private perfofarionService: PerforationService,
    private appErrorHandler: ErrorHandler
  ) {
    this.drawingTools = new BehaviorSubject([]);
    this.utilTools = new BehaviorSubject([]);
    this.measureTools = new BehaviorSubject([]);
    this.eckpunkteTools = new BehaviorSubject([]);
    this.contureTools = new BehaviorSubject([]);
    this.initializeTools();
    this.activeTool = new Subject<Tool>();
    this.pointerMeshProvider = new PointerMeshProvider(
      this.sceneProvider,
      this.pointerController,
      this
    );
    this.activeTool.subscribe((tool) => {
      this.activeToolObject = tool;
    });

    this.pointerController.pointerState.subscribe((state) => {
      if (this.activeToolObject) {
        if (state.eventType === PointerEventType.MOVE) {
          this.activeToolObject.onMouseMove(state);
          this.view3dProvider.mevacoView3D.shouldUpdate = true;
        } else if (state.eventType === PointerEventType.CLICK) {
          console.log("CLICK");
          this.activeToolObject.onMouseClick(state);
          this.view3dProvider.mevacoView3D.shouldUpdate = true;
        } else if (state.eventType === PointerEventType.DOWN) {
          if (state.button === ButtonType.LEFT) {
            this.activeToolObject.onMouseDown(state);
            this.view3dProvider.mevacoView3D.shouldUpdate = true;
          }
        } else if (state.eventType === PointerEventType.UP) {
          this.activeToolObject.onMouseUp(state);
          this.view3dProvider.mevacoView3D.shouldUpdate = true;
        }
      }
    });

    this.onClosestSegmentChangedSub = combineLatest([
      this.store.pipe(select(getPlate)),
      this.store.pipe(select(getClosestSegmentVisibilityState)),
      this.pointerController.pointerState,
    ]).subscribe(([plate, state, pointer]) => {
      this.state = state;
      if (this.activeToolObject) {
        if (state) {
          const horizontal = this.pointerController.nearestHorizontalSegment(
            pointer.position,
            this.shapeWithHolesProvider.foreachSegmentInModel.bind(
              this.shapeWithHolesProvider,
              pointer.position
            )
          );
          const vertical = this.pointerController.nearestVerticalSegment(
            pointer.position,
            this.shapeWithHolesProvider.foreachSegmentInModel.bind(
              this.shapeWithHolesProvider,
              pointer.position
            )
          );
          this.activeToolObject.onClosestSegmentsChanged({
            verticalSegment: vertical,
            horizontalSegment: horizontal,
          });
        } else {
          this.activeToolObject.onClosestSegmentsChanged({
            verticalSegment: null,
            horizontalSegment: null,
          });
        }
      }
    });
    this.sceneProvider.getSubscription().subscribe((scene) => {
      if (this.keyboardSub) {
        this.scene.onPreKeyboardObservable.remove(this.keyboardSub);
        this.keyboardSub = null;
      }
      if (scene) {
        this.scene = scene;
        this.keyboardSub = scene.onPreKeyboardObservable.add(
          this.keyboardEvents.bind(this)
        );
      }
    });

    this.pointerController.pointerState.subscribe(
      (pointerState: PointerState) => {
        this.latestPointerState = pointerState;
      }
    );

    combineLatest([this.store.pipe(select(getStep)), this.store.pipe(select(getProductType))])
      .subscribe(([step, productType]) => {
        this._selectTool.onCancel();
        // TODO find better solution
        if (
          step !== Step.import &&
          step !== Step.import_attachment &&
          step !== Step.import_pattern
        ) {
          this._importTool.onCancel();
        }

        if (step === Step.shape) {
          const drawingTools: NamedTool[] = [
            {
              name: "Rectangle",
              tool: this.rectangleTool,
            },
            {
              name: "Circle",
              tool: this.circletool,
            },
            {
              name: "Polyline",
              tool: this.polylinetool,
            },
            {
              name: "Bow",
              tool: this.bowTool,
            },
          ];
          const otherTools: NamedTool[] = [
            {
              name: "Select",
              tool: this._selectTool,
            },
            {
              name: "TreatCorner",
              tool: this._treatCornerTool,
            },
            {
              name: "HelpLine",
              tool: this._helpLineTool,
            },
          ];
          const measureTools: NamedTool[] = [
            {
              name: "Measurement",
              tool: this.measurementTool,
            },
            {
              name: "Angle",
              tool: this.angleMeasurementTool,
            },
          ];
          this.drawingTools.next(drawingTools);
          this.utilTools.next(otherTools);
          this.measureTools.next(measureTools);
          this.eckpunkteTools.next([]);
          this.contureTools.next([]);
          this.setTool(this._selectTool);
        }
        if (step === Step.pattern) {
          this.eckpunkteTools.next([]);
          this.contureTools.next([]);
          if (productType === ProductType.PerforatedSheets) {
            const drawingTools: NamedTool[] = [
              {
                name: "Rectangle",
                tool: this.rectangleTool,
              },
              {
                name: "Circle",
                tool: this.circletool,
              },
              {
                name: "Polyline",
                tool: this.polylinetool,
              },
              {
                name: "Bow",
                tool: this.bowTool,
              },
            ];
            const otherTools: NamedTool[] = [
              {
                name: "Select",
                tool: this._selectTool,
              },
              {
                name: "TreatCorner",
                tool: this._treatCornerTool,
              },
              {
                name: "HelpLine",
                tool: this._helpLineTool,
              }, {
                name: "Adjustment",
                tool: this.perforationModeTool,
              }
            ];
            const measureTools: NamedTool[] = [
              {
                name: "Measurement",
                tool: this.measurementTool,
              },
              {
                name: "Angle",
                tool: this.angleMeasurementTool,
              },
            ];
            this.drawingTools.next(drawingTools);
            this.utilTools.next(otherTools);
            this.measureTools.next(measureTools);
            this.setTool(this._selectTool);
          } else {
            this.drawingTools.next([]);
            this.utilTools.next([{
              name: "Select",
              tool: this._selectTool,
            // }, {
            //   name: "ExpandedMetalTool",
            //   tool: this._expandedMetalModeTool,
            }, {
              name: "HelpLine",
              tool: this._helpLineTool,
            }]);
            this.measureTools.next([{
                name: "Measurement",
                tool: this.measurementTool,
              },
              {
                name: "Angle",
                tool: this.angleMeasurementTool,
              }]);
            this.setTool(this._selectTool);
          }

        }
        if (step === Step.attachment) {
          const otherTools: NamedTool[] = [
            {
              name: "Select",
              tool: this._selectTool,
              toolTip: this.translationProvider.translate(
                "Select And Move",
                "designer"
              ),
            },
            {
              name: "HelpLine",
              tool: this._helpLineTool,
            },
            {
              name: "Manual",
              tool: this._mountingTool,
              toolTip: this.translationProvider.translate(
                "Manual Mounting Placing",
                "designer"
              ),
            },
            {
              name: "Assisted",
              tool: this._assistedMountingTool,
              toolTip: this.translationProvider.translate(
                "Assisted Mounting Placing",
                "designer"
              ),
            },
          ];
          const measureTools: NamedTool[] = [
            {
              name: "Measurement",
              tool: this.measurementTool,
              toolTip: this.translationProvider.translate("Measurment"),
            },
            {
              name: "Angle",
              tool: this.angleMeasurementTool,
              toolTip: this.translationProvider.translate("Angle"),
            },
          ];
          this.drawingTools.next([]);
          this.utilTools.next(otherTools);
          this.measureTools.next(measureTools);
          this.eckpunkteTools.next([]);
          this.contureTools.next([]);
          this.setTool(this._mountingTool);
        } else if (step === Step.design) {
          this.drawingTools.next([]);
          this.utilTools.next([]);
          this.measureTools.next([]);
          this.eckpunkteTools.next([]);
          this.contureTools.next([]);
          this.setTool(this._selectTool);
        } else if (step === Step.import) {
          const otherTools: NamedTool[] = [
            {
              name: "Import",
              tool: this._importTool,
              hidden: of(true),
            },
          ];
          this.drawingTools.next([]);
          this.measureTools.next([]);
          this.utilTools.next(otherTools);
          this.eckpunkteTools.next([]);
          this.contureTools.next([]);
          this.setTool(this._importTool);
        } else if (step === Step.import_pattern) {
          const drawingTools: NamedTool[] = [
            {
              name: "Rectangle",
              tool: this.rectangleTool,
            },
            {
              name: "Circle",
              tool: this.circletool,
            },
            {
              name: "Polyline",
              tool: this.polylinetool,
            },
            {
              name: "Bow",
              tool: this.bowTool,
            },
          ];
          const eckpunkteTools: NamedTool[] = [
            {
              name: "Punkte",
              tool: this._selectPerforationImportTool,
              toolTip: this.translationProvider.translate(
                "CalculatePerforationAreaTool",
                "designer"
              ),
              controls: [
                {
                  type: ToolControlType.actionButton,
                  name: "CalculatePerfArea",
                  action: this._selectPerforationImportTool.onConfirm.bind(
                    this._selectPerforationImportTool
                  ),
                  enable: of(true),
                },
              ],
            },
          ];

          const contureTools: NamedTool[] = [
            {
              name: "Kontur",
              tool: this.outlinePerforationImportTool,
            },
          ];

          const otherTools: NamedTool[] = [
            {
              name: "Import",
              tool: this._importTool,
              hidden: of(true),
            },
            {
              name: "Select",
              tool: this.selectTool,
              //toolTip: this.translationProvider.translate('CalculatePerforationAreaTool', 'designer')
            },
            {
              name: "HelpLine",
              tool: this.helpLineTool,
            },
          ];

          this.drawingTools.next(drawingTools);
          this.measureTools.next([]);
          this.utilTools.next(otherTools);
          this.eckpunkteTools.next(eckpunkteTools);
          this.contureTools.next(contureTools);
          this.setTool(this._selectPerforationImportTool);
        } else if (step === Step.import_attachment) {
          const otherTools: NamedTool[] = [
            {
              name: "FixingImport",
              tool: this._attachmentImportTool,
              /*controls: [
                {
                  type: ToolControlType.actionButton,
                  name: 'Add Mountings',
                  action: this._attachmentImportTool.addMountings.bind(this._attachmentImportTool),
                  enable: this._importTool.CanImport()
                }
              ]*/
            },
            {
              name: "HelpLine",
              tool: this.helpLineTool,
            },
          ];
          this.drawingTools.next([]);
          this.utilTools.next(otherTools);
          this.measureTools.next([]);
          this.eckpunkteTools.next([]);
          this.contureTools.next([]);
          this.setTool(this._attachmentImportTool);
        } else if (step === Step.cockpit || step === Step.template) {
          this.drawingTools.next([]);
          this.utilTools.next([]);
          this.measureTools.next([]);
          this.eckpunkteTools.next([]);
          this.contureTools.next([]);
          this.setTool(this._selectTool);
        }
      });
    this.store.pipe(select(getSnapOptions)).subscribe((v) => {
      if (v) {
        if (this.activeToolObject === this._helpLineTool && !v.helpLines) {
          this.setTool(this.selectTool);
        }
      }
    });
  }

  public get selectPerforationImportTool(): SelectPerforationImportTool {
    return this._selectPerforationImportTool;
  }

  public get perforationModeTool(): PerforationModeTool {
    return this._perforationModeTool;
  }

  public get perforationAdjustmentTool(): PerforationAdjustmentTool {
    return this._perforationAdjustmentTool;
  }

  public get wallpapperMoveTool(): WallpaperMoveTool {
    return this._wallpapperMoveTool;
  }

  public get helpLineTool(): HelpLineTool {
    return this._helpLineTool;
  }

  public get expMetalCustomCutTool(): ExpandedMetalModeTool {
    return this._expandedMetalModeTool;
  }

  public refreshTool(): void {
    this.setTool(this.activeToolObject);
  }

  public get assistedMountingTool(): AssistedMountingTool {
    return this._assistedMountingTool;
  }

  public get mountingTool(): MountingTool {
    return this._mountingTool;
  }

  public get attachmentImportTool(): AttachmentImportTool {
    return this._attachmentImportTool;
  }

  public get selectTool(): SelectTool {
    return this._selectTool;
  }

  public tryDelete() {
    this._selectTool.tryDelete();
  }

  public get cassettePerforationTool(): CassettePerforationCreatorTool {
    return this._cassettePerforationTool;
  }

  public get importTool(): ImportSelectTool {
    return this._importTool;
  }

  getDrawingTools(): Observable<NamedTool[]> {
    return this.drawingTools;
  }

  getUtilTools(): Observable<NamedTool[]> {
    const utilTools = this.utilTools;
    return this.utilTools;
  }

  getEckpunkteTools(): Observable<NamedTool[]> {
    return this.eckpunkteTools;
  }

  getContureTools(): Observable<NamedTool[]> {
    return this.contureTools;
  }

  getMeasureTools(): Observable<NamedTool[]> {
    return this.measureTools;
  }

  public get treatCornerTool(): TreatCornerTool {
    return this._treatCornerTool;
  }

  initializeTools() {
    this._helpLineTool = new HelpLineTool(
      this.store,
      this.sceneProvider,
      this.translationProvider
    );
    this._mountingTool = new MountingTool(
      this.store,
      this.sceneProvider,
      this.measurementsManagerProvider,
      this,
      this.translationProvider
    );
    this._assistedMountingTool = new AssistedMountingTool(
      this.store,
      this.sceneProvider,
      this.measurementsManagerProvider,
      this,
      this.translationProvider
    );
    this.circletool = new CircleTool(
      this.store,
      this.sceneProvider,
      this.webcadProvider,
      this,
      this.translationProvider,
      this.measurementsManagerProvider,
      this.appErrorHandler
    );
    this.rectangleTool = new RectangleTool(
      this.store,
      this.sceneProvider,
      this.webcadProvider,
      this.measurementsManagerProvider,
      this,
      this.translationProvider
    );
    this._selectTool = new SelectTool(
      this.pointerController,
      this.store,
      this.sceneProvider,
      this.measurementsManagerProvider,
      this.webcadProvider,
      this.dragArrowsMananagerProvider,
      this.translationProvider
    );
    this.rotationTool = new RotationTool(
      this.store,
      this.sceneProvider,
      this.measurementsManagerProvider
    );
    this.bowTool = new BowTool(
      this.store,
      this.sceneProvider,
      this.webcadProvider,
      this.measurementsManagerProvider,
      this,
      this.translationProvider
    );
    this.polylinetool = new LineMainTool(
      this.store,
      this.sceneProvider,
      this.webcadProvider,
      this.measurementsManagerProvider,
      this,
      this.translationProvider
    );
    window.addEventListener(
      "keyup",
      this.polylinetool.changeLineType.bind(this.polylinetool)
    );
    this.measurementTool = new MeasurementTool(
      this.store,
      this.sceneProvider,
      this.translationProvider,
      this.webcadProvider
    );
    this.angleMeasurementTool = new AngleMeasurementTool(
      this.store,
      this.sceneProvider,
      this.webcadProvider,
      this.translationProvider
    );
    this._perforationAdjustmentTool = new PerforationAdjustmentTool(
      this.store,
      this.sceneProvider,
      this.translationProvider
    );
    this._wallpapperMoveTool = new WallpaperMoveTool(
      this.store,
      this.perfofarionService
    );
    this._importTool = new ImportSelectTool(
      this.store,
      this.actions,
      this.sceneProvider,
      this.translationProvider
    );

    this._selectPerforation = new SelectPerforationTool(
      this.pointerController,
      this.store,
      this.sceneProvider,
      this.translationProvider
    );

    this._attachmentImportTool = new AttachmentImportTool(
      this.store,
      this.sceneProvider,
      this.translationProvider
    );
    this._treatCornerTool = new TreatCornerTool(
      this.pointerController,
      this.store,
      this.sceneProvider,
      this.translationProvider
    );
    this.outlinePerforationImportTool = new OutlinePerforationImportTool(
      this.store,
      this.translationProvider
    );
    this._selectPerforationImportTool = new SelectPerforationImportTool(
      this.pointerController,
      this.store,
      this.sceneProvider,
      this.translationProvider
    );

    this._perforationModeTool = new PerforationModeTool(
      this.pointerController,
      this.store,
      this.perfofarionService
    );
    this._cassettePerforationTool = new CassettePerforationCreatorTool(
      this.store
    );
    this._expandedMetalModeTool = new ExpandedMetalModeTool(
      this.store,
      this.translationProvider
    );
  }

  setTool(tool: Tool): void {
    //setTool will fail if scene is not ready so this should hold tha activation it till we have it
    this.sceneProvider
      .getSubscription()
      .pipe(
        filter((scene) => !!scene),
        take(1)
      )
      .subscribe(() => {
        if (this.activeToolObject) {
          this.activeToolObject.onCancel();
          if (this.selectTool.isDirty()) {
            this.selectTool.onCancel();
          }
        }
        if (tool) {
          this.activeTool.next(tool);
          if (tool) {
            tool.activate();
          }
        }
      });
  }

  keyboardEvents(
    eventData: KeyboardInfo,
    eventState: EventState
  ) {
    if (eventData.type === KeyboardEventTypes.KEYUP) {
      if (eventData.event.key === "Escape") {
        if (this.activeToolObject) {
          if (this.activeToolObject.isDirty()) {
            this.activeToolObject.reset();
            if (this.activeToolObject === this._selectTool) {
              this.activeToolObject.onCancel();
            }
          } else {
            this.activeToolObject.onCancel();
            this.setTool(this._selectTool);
          }
          this.view3dProvider.mevacoView3D.shouldUpdate = true;
        }
      } else if (eventData.event.key === "Enter") {
        if (this.activeToolObject) {
          eventData.event.preventDefault();
          // eventData.event.stopPropagation();
          this.activeToolObject.onConfirm();
          this.view3dProvider.mevacoView3D.shouldUpdate = true;
        }
      }
    }
  }

  getToolObservable(): Subject<Tool> {
    return this.activeTool;
  }

  setMode(actionType: ActionType) {
    (<DrawingTool>this.activeToolObject).mode = actionType;
  }

  public getNearestHorizontalSegmentToPoint(
    point: Vector2
  ): SegmentAndProjectedPoint {
    if (this.state) {
      return this.pointerController.nearestHorizontalSegment(
        point,
        this.shapeWithHolesProvider.foreachSegmentInModel.bind(
          this.shapeWithHolesProvider,
          point
        )
      );
    } else {
      return null;
    }
  }

  public getNearestVerticalSegmentToPoint(
    point: Vector2
  ): SegmentAndProjectedPoint {
    if (this.state) {
      return this.pointerController.nearestVerticalSegment(
        point,
        this.shapeWithHolesProvider.foreachSegmentInModel.bind(
          this.shapeWithHolesProvider,
          point
        )
      );
    } else {
      return null;
    }
  }

  resetImportTools() {
    this._attachmentImportTool.reset();
    this._importTool.reset();
    this.outlinePerforationImportTool.reset();
    this._selectPerforationImportTool.reset();
  }
}
