export interface BasicPlateParams {
  a: number;
  b: number;
  e1: number;
  e2: number;
  f1: number;
  f2: number;
  //name: string;
}

export interface ExpandedMetalParams {
  width: number;
  height: number;
}

export interface Parallelogram extends BasicPlateParams {
  tilt: number;
  ha: number;
  b1: number;
}

export interface ParallelogramExpMetal {
  a: number;
  b: number;
  tilt: number;
  ha: number;
  b1: number;
}

export interface Perf {
  size: number;
  margin: number;
}

export interface MultiShape {
  plateParams: BasicPlateParams;
  rowNumber: number;
  columnNumber: number;
  rows: Perf[];
  columns: Perf[];
}

export interface Staircase {
  ha: number;
  e1: number;
  e2: number;
  tilt: number;
  a1: number;
  b1: number;
  f1: number;
  f2: number;
  a2: number;
  b2: number;
  b3: number;
}

export interface RectangleNotch extends BasicPlateParams {
  x1: number;
  x2: number;
  x3: number;
  x4: number;
  y1: number;
  y2: number;
  y3: number;
  y4: number;
}
export interface RectangleNotchInputFlags {
  x1: boolean;
  x2: boolean;
  x3: boolean;
  x4: boolean;
  y1: boolean;
  y2: boolean;
  y3: boolean;
  y4: boolean;
}
export interface BalconyCorner {
  a: number;
  b: number;
  b1: number;
  b2: number;
  e1: number;
  e2: number;
  f1: number;
  f2: number;
  f3: number;
  x1: number;
  x2: number;
  x3: number;
  x4: number;
  x5: number;
  y1: number;
  y2: number;
  y3: number;
  y4: number;
  y5: number;
}

export interface BalconyCornerInputFlags {
  x1: boolean;
  x2: boolean;
  x3: boolean;
  x4: boolean;
  x5: boolean;
  y1: boolean;
  y2: boolean;
  y3: boolean;
  y4: boolean;
  y5: boolean;
}

export interface Trapeze {
  a: number;
  b1: number;
  b2: number;
  alpha: number;
  beta: number;
  e1: number;
  e2: number;
  f1: number;
  f2: number;
  ha1: number;
  ha2: number;
  hb: number;
}

export interface TrapezeExpandedMetal {
  a: number;
  b1: number;
  b2: number;
  alpha: number;
  beta: number;
  ha1: number;
  ha2: number;
  hb: number;
}

export interface SectionCassette {
  l: number;
  b: number;
  h: number;
  t: number;
}

export interface Umschlag {
  l: number;
  b: number;
  h: number;
}

export interface ConfigurableTemplates {
  basicRectangleTemplate: BasicPlateParams;
  expandedMetalTemplate: ExpandedMetalParams;
  multiShape: MultiShape;
  parallelogramTemplate: Parallelogram;
  staircaseTemplate: Staircase;
  rectangleNotchTemplate: RectangleNotch;
  balconyCornerTemplate: BalconyCorner;
  trapezeTemplate: Trapeze;
  // trapezeExpMetalTemplate: TrapezeExpandedMetal;
  // parallelogramExpMetalTemplate: ParallelogramExpMetal;
}

export const initialConfigurableTemplates: ConfigurableTemplates = {
    basicRectangleTemplate: {
      a: 800,
      b: 1250,
      f2: 50,
      e1: 50,
      e2: 50,
      f1: 50,
      //name: ''
    },
    expandedMetalTemplate:{
      width: 1300,
      height: 500
    },
    multiShape: {
      plateParams: {
        f2: 50,
        a: 800,
        b: 1250,
        e1: 50,
        e2: 50,
        f1: 50,
        //name: ''
      },
      columns: [{margin: 0, size: 1150}],
      rows: [{margin: 0, size: 700}],
      columnNumber: 1,
      rowNumber: 1
    },
    parallelogramTemplate: {
      f2: 50,
      a: 800,
      b: 1250,
      e1: 50,
      e2: 50,
      f1: 50,
      b1: 602.8,
      ha: 1001.7,
      tilt: 0.92502450355
    },
    staircaseTemplate: {
      tilt: 0.92502450355,
      a1: 300,
      b3: 200,
      b1: 400,
      e1: 50,
      e2: 50,
      f1: 50,
      f2: 50,
      ha: 400,
      a2: 500,
      b2: 250
    },
    rectangleNotchTemplate: {
      a: 800,
      b: 1250,
      f2: 75,
      e1: 75,
      e2: 75,
      f1: 75,
      x1: 50,
      x2: 50,
      x3: 50,
      x4: 50,
      y1: 50,
      y2: 50,
      y3: 50,
      y4: 50
    },
    balconyCornerTemplate: {
      a: 800,
      b: 1200,
      b1: 600,
      b2: 600,
      f2: 75,
      e1: 75,
      e2: 75,
      f1: 75,
      f3: 75,
      x1: 50,
      x2: 50,
      x3: 50,
      x4: 50,
      x5: 50,
      y1: 50,
      y2: 50,
      y3: 50,
      y4: 50,
      y5: 50
    },
    trapezeTemplate: {
      a: 800,
      hb: 200,
      e1: 50,
      e2: 50,
      f1: 50,
      f2: 50,
      ha1: 824.6,
      alpha: 1.3257521,
      b1: 1250,
      b2: 850,
      beta: 1.3257521,
      ha2: 824.6
    }
  };
