import { Component } from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getSelectedExpandedMetalTypeOfCut, MevacoState} from '../../store';
import {ToolProvider} from '../../providers/tool.provider';
import {map} from 'rxjs/operators';
import {TranslationProvider} from '../../providers/translation.provider';
import {offset} from "@popperjs/core";
import {multiplyVector2byScalar, Vector2} from "webcad/math";
import {SetPerforationOffset} from "../../store/actions/mountings.actions";
import {ChangePatternOffset} from "../../store/actions/current-position.actions";

@Component({
  selector: 'app-exp-metal-custom-cut',
  templateUrl: './exp-metal-custom-cut.component.html',
  styleUrls: ['./exp-metal-custom-cut.component.css']
})
export class ExpMetalCustomCutComponent {
  public customCutTool = this.toolProvider.expMetalCustomCutTool;
  public customCutSelected = this.store.pipe(select(getSelectedExpandedMetalTypeOfCut), map( toc => toc === 'M'));
  public customCutToolActive = this.toolProvider.getToolObservable().pipe( map(t => t === this.customCutTool));

  public offset = 1;

  constructor(
    private store: Store<MevacoState>,
    private toolProvider: ToolProvider,
    private translationProvider: TranslationProvider
  ) {
  }

  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }

  movePattern(dir: Vector2) {
    this.store.dispatch( new ChangePatternOffset( multiplyVector2byScalar( dir , (+this.offset) / 1000) ));
  }

}
