<div class="element-creator">
  <div class="element-creator-header">
    <h2 class="element-creator-title">
      {{ translate("Create Position", "designer") }}
    </h2>
    <span class="element-creator-desc">{{
      translate("AboutHolePattern", "designer")
    }}</span>
  </div>

  <div class="element-creator-content">
    <div class="element-creator-grid">
      <div *ngIf="isElementCreatorItemVisible(ElementCreatorTemplateTypeEnum.NewDesign)" class="element-creator-item">
        <div class="item-img-wrapper">
          <img src="assets/Empty.svg" />
        </div>
        <h2 class="item-title">
          {{ translate("New Design", "designer") }}
        </h2>
        <span class="item-description">
          {{ translate("ElementCreatorDescription", "designer") + "." }}
        </span>
        <div class="item-btn-container">
          <button
            type="button"
            class="btn btn-primary item-btn"
            (click)="addEmptyPosition()"
          >
            {{ translate("Add_Empty_Design", "designer") }}
          </button>
        </div>
      </div>

      <div *ngIf="isElementCreatorItemVisible(ElementCreatorTemplateTypeEnum.FromDxf)" class="element-creator-item">
        <div class="item-img-wrapper">
          <img src="assets/Upload.svg" />
        </div>

        <h2 class="item-title">
          {{ translate("Own Template") }}
        </h2>

        <span class="item-description">
          {{ translate("SelectDxfFile", "designer") + ":" }}
        </span>

        <div class="use-blocks__container">
          <mat-checkbox
            class="base-checkbox"
            name="useBlocks"
            #useBlocks
            color="primary"
          >
            <div class="use-blocks__checkbox-content">
              <span class="text">{{ translate("Use blocks?") }}</span>
              <div
                class="use-blocks__checkbox-hint"
                container="body"
                placement="right"
                container="body"
                [ngbTooltip]="translate('use blocks tooltip', 'designer')"
              >
                <img src="assets/hint-icon.svg" alt="hint" />
              </div>
            </div>
          </mat-checkbox>
        </div>

        <dropdown
          [model]="unitsDropdown"
          [dropdownMenuWidth]="templateMenuWidth"
        ></dropdown>

        <form (ngSubmit)="submitFile(fileChosen.files)">
          <label
            class="item-upload-window"
            #dragArea
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)"
            (drop)="onDrop($event)"
          >
            <div class="upload-window-content">
              <div class="upload-window-img-wrapper">
                <div class="upload-window-img"></div>
              </div>
              <div class="upload-window-description">
                <img
                  *ngIf="fileChosen.value"
                  src="assets/CAD_Icon.svg"
                  alt="cad-icon"
                  class="upload-window-icon"
                />
                <span class="upload-window-text" *ngIf="!fileChosen.value">{{
                  translate("Choose a file", "designer")
                }}</span>
                <span class="upload-window-text" *ngIf="fileChosen.value">{{
                  fileChosen.value.split("\\").pop()
                }}</span>
              </div>
            </div>
            <input
              type="file"
              name="upload-file"
              (change)="submitFile(fileChosen.files)"
              #fileChosen
              style="display: none"
            />
          </label>
        </form>
      </div>

      <div *ngIf="isElementCreatorItemVisible(ElementCreatorTemplateTypeEnum.MevacoTemplate)" class="element-creator-item">
        <div class="item-img-wrapper">
          <img src="assets/Configurable.svg" />
        </div>
        <h2 class="item-title">
          {{ translate("Mevaco Templates", "designer") }}
        </h2>
        <span class="item-description">
          {{ translate("ChooseTemplates", "designer") + ":" }}
        </span>
        <div class="item-dropdown">
          <dropdown
            [model]="templatesDropDown"
            [tooltipConfig]="{ fieldName: 'templateType' }"
            [dropdownMenuWidth]="templateMenuWidth"
          ></dropdown>
        </div>
      </div>

      <div *ngIf="isElementCreatorItemVisible(ElementCreatorTemplateTypeEnum.CasetteTemplate)" class="element-creator-item">
        <div class="item-img-wrapper">
          <img src="assets/casseteIconSecond.svg" />
        </div>
        <h2 class="item-title">
          {{ translate("Casseten", "designer") }}
        </h2>
        <span class="item-description">
          {{ translate("ChooseTemplates", "designer") + ":" }}
        </span>
        <div class="item-dropdown">
          <dropdown
            [model]="cassettenDropDown"
            [tooltipConfig]="{ fieldName: 'templateType' }"
            [dropdownMenuWidth]="templateMenuWidth"
          ></dropdown>
        </div>
      </div>
    </div>

    <div class="user-templates-section">
      <h2 class="user-templates-title">
        {{ translate("User Templates", "designer") }}
      </h2>
      <div class="user-templates-carousel">
        <p-carousel numVisible="5" [circular]="true" [value]="userTemplates">
          <ng-template let-item pTemplate="item">
            <user-template-item [userTemplate]="item"></user-template-item>
          </ng-template>
        </p-carousel>
      </div>
    </div>
  </div>
</div>

<ng-template #basicRectangle let-modal>
  <mevaco-basic-rectangle
    (dismissModal)="modal.dismiss($event)"
    (closeModal)="modal.close($event)"
    (isModalOpen)="isTemplateModalOpenChange($event)"
    >
  </mevaco-basic-rectangle>
</ng-template >

<ng-template #expandedMetalBasicRectangle let-modal>
  <mevaco-rectangle-expanded-metal
(dismissModal)="modal.dismiss($event)"
(closeModal)="modal.close($event)"
(isModalOpen)="isTemplateModalOpenChange($event)"
></mevaco-rectangle-expanded-metal>
</ng-template>

<ng-template #expandedMetalTrapeze let-modal>
  <mevaco-trapez-expanded-metal
    (dismissModal)="modal.dismiss($event)"
    (closeModal)="modal.close($event)"
    (isModalOpen)="isTemplateModalOpenChange($event)"
  ></mevaco-trapez-expanded-metal>
</ng-template>

<ng-template #expandedMetalParallelogram let-modal>
  <mevaco-parallelogram-expanded-metal
    (dismissModal)="modal.dismiss($event)"
    (closeModal)="modal.close($event)"
    (isModalOpen)="isTemplateModalOpenChange($event)"
  ></mevaco-parallelogram-expanded-metal>
</ng-template>


<ng-template #multiShape let-modal>
  <mevaco-multi-shape
    (dismissModal)="modal.dismiss($event)"
    (closeModal)="modal.close($event)"
    (isModalOpen)="isTemplateModalOpenChange($event)"
    >>
  </mevaco-multi-shape>
</ng-template>

<ng-template #tilted let-modal>
  <mevaco-tilted-plate
    (dismissModal)="modal.dismiss($event)"
    (closeModal)="modal.close($event)"
    (isModalOpen)="isTemplateModalOpenChange($event)"
    >>
  </mevaco-tilted-plate>
</ng-template>

<ng-template #staircase let-modal style="max-width: 800px">
  <mevaco-staircase
    (dismissModal)="modal.dismiss($event)"
    (closeModal)="modal.close($event)"
    (isModalOpen)="isTemplateModalOpenChange($event)"
    >>
  </mevaco-staircase>
</ng-template>

<ng-template #rectangleNotch let-modal>
  <mevaco-rectangle-notch
    (dismissModal)="modal.dismiss($event)"
    (closeModal)="modal.close($event)"
    (isModalOpen)="isTemplateModalOpenChange($event)"
    >>
  </mevaco-rectangle-notch>
</ng-template>

<ng-template #balconyCorner let-modal>
  <mevaco-balcony-corner
    (dismissModal)="modal.dismiss($event)"
    (closeModal)="modal.close($event)"
    (isModalOpen)="isTemplateModalOpenChange($event)"
    >>
  </mevaco-balcony-corner>
</ng-template>

<ng-template #trapeze let-modal>
  <mevaco-trapez
    (dismissModal)="modal.dismiss($event)"
    (closeModal)="modal.close($event)"
    (isModalOpen)="isTemplateModalOpenChange($event)"
    >
  </mevaco-trapez>
</ng-template>

<!--
<ng-template #simpleSectionCassete let-modal>
  <simple-section-casset
      (dismissModal)="modal.dismiss($event)"
     (closeModal)="modal.close($event)"
      (isModalOpen)="isTemplateModalOpenChange($event)">>
  </simple-section-casset>
</ng-template>

-->
<ng-template #umschlagCassete let-modal>
  <umschlag-casset
    (dismissModal)="modal.dismiss($event)"
    (closeModal)="modal.close($event)"
    (isModalOpen)="isTemplateModalOpenChange($event)"
    >>
  </umschlag-casset>
</ng-template>

<ng-template #singleBendCassete let-modal>
  <single-bend-casset
    (dismissModal)="modal.dismiss($event)"
    (closeModal)="modal.close($event)"
    (isModalOpen)="isTemplateModalOpenChange($event)"
    >>
  </single-bend-casset>
</ng-template>

<ng-template #twoBendsCassete let-modal>
  <two-bends-casset
    (dismissModal)="modal.dismiss($event)"
    (closeModal)="modal.close($event)"
    (isModalOpen)="isTemplateModalOpenChange($event)"
    >>
  </two-bends-casset>
</ng-template>

<ng-template #korbCassete let-modal>
  <korb-casset
    (dismissModal)="modal.dismiss($event)"
    (closeModal)="modal.close($event)"
    (isModalOpen)="isTemplateModalOpenChange($event)"
    >>
  </korb-casset>
</ng-template>

<ng-template let-modal #flatCassete>
  <flat-casset
    (dismissModal)="modal.dismiss($event)"
    (closeModal)="modal.close($event)"
    (isModalOpen)="isTemplateModalOpenChange($event)"
    >>
  </flat-casset>
</ng-template>

<ng-template #loadingSpinner let-modal>
  <div class="loading-spinner__container">
    <mat-spinner mode="indeterminate" diameter="70"></mat-spinner>
    <span class="loading-spinner__label"
      >{{ translate("The file is being processed", "designer") }}...</span
    >
  </div>
</ng-template>
