import { UnderPointerType } from "app/model/pointer-state.model";
import { PointerCollider } from "webcad/collision";
import { Pointer } from "webcad/collision/pointer";
import { PointerIntersection } from "webcad/collision/pointer-intersection";
import { CursorType } from "./cursor.type";
import {MevacoCollider} from "./mevaco.collider";
import {ColliderPriority} from "./collider-priorities";
import {PatternGrid} from "../../model/product-configuration/element.model";
import {ShapeWithHoles} from "../../model/shape-with-holes";
import {IsPointInsideOfShape} from "webcad/models";

export class PatternGridCollider implements MevacoCollider {
    objectType = UnderPointerType.NODE;
    object = this.grid;
    cursorType = CursorType.cross;
    priority = ColliderPriority.NODE;
    name = 'PatternGridCollider';
    constructor(public grid: PatternGrid[], public shape: ShapeWithHoles) {
    }

    getIntersection(pointer: Pointer, epsilon: number): PointerIntersection {
      let result: PointerIntersection = null;
      let distSq = 10000000;
      if (IsPointInsideOfShape(pointer.onWorkingPlane, this.shape.conture) ) {
        for (let i = 0; i < this.grid.length; i++) {
          const grid = this.grid[i];
            let ix = Math.floor( (pointer.onWorkingPlane.x - grid.startX) / grid.deltaX );
            const dx = (pointer.onWorkingPlane.x - grid.startX) % grid.deltaX;
            if (dx > grid.deltaX / 2) {
              ix++;
            }
            const x = grid.startX + grid.deltaX * ix;

            let iy = Math.floor( (pointer.onWorkingPlane.y - grid.startY) / grid.deltaY );
            const dy = (pointer.onWorkingPlane.y - grid.startY) % grid.deltaY;
            if (dy > grid.deltaY / 2) {
              iy++;
            }
            const y = grid.startY + grid.deltaY * iy;
            const dSq = (pointer.onWorkingPlane.x - x) * (pointer.onWorkingPlane.x - x) + (pointer.onWorkingPlane.y - y) * (pointer.onWorkingPlane.y - y);
            if (dSq < epsilon * epsilon && dSq < distSq) {
              distSq = dSq;
              result = {
                position: { x: x, y: y, z: 0 },
                collider: this,
              };
            }
        }
      }
      return result;
    }
    compare(other: PointerCollider): boolean {
      return (
        other instanceof PatternGridCollider
      );
    }

}
