import {Drawing} from '../../model/drawing.model';
import {PolylineViewModel} from '../../model/view-model/polylines.viewModel';

export class ExpmetCutModelProvider {
  private model: Drawing;
  private viewModel: PolylineViewModel;

  getViewModel(newModel: Drawing): PolylineViewModel {
    if (!newModel || !newModel.showPerforationBorder || !newModel.plate || !newModel.plate.cutShapeWithHoles ) {
      this.viewModel = null;
    } else if (
      !this.model ||
      this.model.plate.cutShapeWithHoles !== newModel.plate.cutShapeWithHoles
    ) {
      const polyline = [newModel.plate.cutShapeWithHoles.conture];
      for (let i = 0; i < newModel.plate.cutShapeWithHoles.holes.length; i++) {
        polyline.push( newModel.plate.cutShapeWithHoles.holes[i] );
      }
      this.viewModel = {polyline: polyline, color: '#1e0a30'};
    }
    this.model = newModel;
    return this.viewModel;
  }

}
