<ng-container *ngIf="!!model">
  <!-- <div class="label">
    {{translate(model.label)}}
  </div> -->
  <div
    ngbDropdown
    (openChange)= "onOpenChange($event)"
  >
    <button
      [disabled]="disabled" class="dropdown-button" id="dropdownBasic1" ngbDropdownToggle
      [class.dropdown-button--selected]="(model.selectedOption | async)"
      [class.dropdown-button--error]="dropdownType === 'ERROR'"
      [class.dropdown-button--warning]="dropdownType === 'WARNING'"
    >
      <div class="dropdown-text-wrapper">
        <dropdown-option [labelText]="model.label" [selected]="true" [option]="model.selectedOption | async" [dropdownOptionType]="dropdownType">
        </dropdown-option>
      </div>
      <div class="dropdown-arrow-wrapper"
      [class.dropdown-arrow-wrapper--selected]="dropdownType === 'DEFAULT' && (model.selectedOption | async)"
      [class.dropdown-arrow-wrapper--error]="dropdownType === 'ERROR' && !(model.selectedOption | async)"
      [class.dropdown-arrow-wrapper--selected-error]="dropdownType === 'ERROR' && (model.selectedOption | async)"
      [class.dropdown-arrow-wrapper--warning]="dropdownType === 'WARNING' && (model.selectedOption | async)"
      [class.dropdown-arrow-wrapper--selected-warning]="dropdownType === 'WARNING' && (model.selectedOption | async)"
      >
        <img class="dropdown-arrow" src="assets/down-arrow.svg" alt="arrow">
      </div>
    </button>

    <div *ngIf="!!tooltipConfig" class="tooltip-placeholder">
      <rule-action [config]="tooltipConfig" (type)="updateType($event)"></rule-action>
    </div>

    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <input #searchElement [class]=" filter.length ? 'filer-show' : 'filer-hide' " [(ngModel)]="filter">
      <div [class]="'dropdown-menu-scroll' + ' ' + extraClass">
        <ng-container *ngIf="model.possibleOptionsWithAny | async as possibleOptions">
          <ng-container *ngFor="let option of (model.options | async)">
            <button class="dropdown-item" *ngIf="filterOption(option)" (click)="changeAction(option)">
              <div>
                <dropdown-option
                  [labelText]="model.label"
                  [selected]="false"
                  [dropdownOptionType]="optionType(possibleOptions, option)"
                  [option]="option">
                </dropdown-option>
              </div>
            </button>
          </ng-container>
        </ng-container>
      </div>
      <button *ngIf="model.showIndividualOption && model.showIndividualOption | async" class="dropdown-item"
              (click)="changeAction(individualOption)">
        <dropdown-option [option]="individualOption"></dropdown-option>
      </button>
    </div>

  </div>
</ng-container>
