<div class="table-row">

  <div class="table-cell-index table-cell--no-bgc w-20-px">
    {{index}}
  </div>


  <div class="flexible-table-cells">
    <div class="table-cell table-cell--center table-cell--light w-13-p">
      <mat-spinner *ngIf="!model.previewImageId || !imageReady" [diameter]="25" mode="indeterminate"></mat-spinner>
      <img *ngIf="!!model.previewImageId" [hidden]="!imageReady" (load)="setImageReady()" [src]="(getImageUrl | async)" class="table-cell-image">
    </div>
    <div class="table-cell w-12-p" [ngClass]="{'table-cell--dark' : index % 2 === 0, 'table-cell--light': index % 2 !== 0}">
      <input class="table-cell-input-number" type="number" [value]="model.quantity" (change)="onQuantityChange($event.target.value)" />
    </div>
    <div class="table-cell w-11-p" [ngClass]="{'table-cell--dark' : index % 2 === 0, 'table-cell--light': index % 2 !== 0}">{{fix(model.aIst)}}
    </div>
    <div class="table-cell w-11-p"  [ngClass]="{'table-cell--dark' : index % 2 === 0, 'table-cell--light': index % 2 !== 0}">{{fix(model.bIst)}}
    </div>
    <div class="table-cell w-11-p"  [ngClass]="{'table-cell--dark' : index % 2 === 0, 'table-cell--light': index % 2 !== 0}">{{area}}m2
    </div>
    <div class="table-cell w-11-p"  [ngClass]="{'table-cell--dark' : index % 2 === 0, 'table-cell--light': index % 2 !== 0}">{{model.toleranceLength}}
    </div>
    <div class="table-cell w-11-p"  [ngClass]="{'table-cell--dark' : index % 2 === 0, 'table-cell--light': index % 2 !== 0}">{{model.toleranceWidth}}
    </div>
    <div class="table-cell w-21-p"  [ngClass]="{'table-cell--dark' : index % 2 === 0, 'table-cell--light': index % 2 !== 0}">
      <textarea type="number" class="table-cell-input-text" (change)="onNoteChange($event.target.value)" [placeholder]="fix(model.a)?.toString() +'x'+ fix(model.b)?.toString()">{{model.note}}</textarea>
    </div>
  </div>

  <div class="table-cell table-cell--center table-cell--no-bgc w-100-px">
    <div class="add-template-position" (click)="makeTemplateOpen()">
    </div>
    <div class="edit-position" (click)="edit()">
    </div>
    <div class="delete-position" (click)="confirmOpen()">
    </div>
  </div>



  <div class="row-overlay-container" [hidden]="!this.show">
    <div class="row-overlay-label">
      {{translate("Are you sure")}}
    </div>
    <div class="row-overlay-btns">
      <button type="button" class="btn btn-primary row-overlay-btn" (click)="delete()">{{translate("Yes")}}</button>
      <button type="button" class="btn row-overlay-btn btn-outline-primary" (click)="confirmOpen()">{{translate("No")}}</button>
    </div>

  </div>
  <div class="row-overlay-container" [hidden]="!this.show2">
    <div class="row-overlay-input-wrapper">
      <label class="row-overlay-label">{{translate("Project name", "designer")}}:</label>
      <input class="row-overlay-input" type="text" [(ngModel)]="projectName" placeholder="Project name">
    </div>
    <div class="row-overlay-btns">
      <button type="button" class="btn btn-primary row-overlay-btn" (click)="makeTemplate()">{{translate("Save")}}</button>
      <button type="button" class="btn row-overlay-btn btn-outline-primary" (click)="copyPosition()">{{translate("Copy")}}</button>
      <button type="button" class="btn row-overlay-btn btn-outline-primary" (click)="makeTemplateOpen()">{{translate("Cancel")}}</button>
    </div>
  </div>
</div>
