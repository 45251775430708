<ng-container *ngIf="customCutToolActive|async">
  <ng-container *ngIf="customCutSelected|async">
    <div class="perf-offset-wrapper">
      <div class="grid-container">
        <div class="grid-item"></div>
        <div class="grid-item">
          <button class="btn-arrow" (click)="movePattern({x:0,y:1})">
            <img class="arrow arrow--up" src="assets/down-arrow.svg" alt="arrow">
          </button>
        </div>
        <div class="grid-item"></div>
        <div class="grid-item">
          <button class="btn-arrow" (click)="movePattern({x:-1,y:0})">
            <img class="arrow arrow--left" src="assets/down-arrow.svg" alt="arrow">
          </button>
        </div>

        <div class="grid-item">
          <input type="number" [(ngModel)]="offset" class="arrow-input" />
        </div>

        <div class="grid-item">
          <button class="btn-arrow" (click)="movePattern({x:1,y:0})">
            <img class="arrow arrow--right" src="assets/down-arrow.svg" alt="arrow">
          </button>
        </div>
        <div class="grid-item"></div>
        <div class="grid-item">
          <button class="btn-arrow" (click)="movePattern({x:0,y:-1})">
            <img class="arrow arrow--down" src="assets/down-arrow.svg" alt="arrow">
          </button>
        </div>
        <div class="grid-item"></div>
      </div>

    </div>


  </ng-container>
  <ng-container *ngIf="!(customCutSelected|async)">
    {{translate("select custom cut first")}}
  </ng-container>
</ng-container>

