import {ExpandedMetalVisualizerModel} from '../expanded-metal.vizualizer';
import {MevacoPage} from '../../model/mevaco-page.model';
import {
  getExpandedMetalGltfHash,
  ProductType,
  Step
} from '../../model/product-configuration/product-configuration.model';
import {ExpandedMetalModeToolModel} from '../../model/tool/expanded-metal-mode-tool.model';

export class ExpandedMetalViewModelProvider {
  private viewModel: ExpandedMetalVisualizerModel = {
    gltfId: null,
    shape: null,
    patternGrids: undefined,
    hexColorString: '#B0B0B0',
    toolOffset: null,
    toleranceLength: 0,
    toleranceWidth: 0,

  };

  getViewModel(newModel: MevacoPage): ExpandedMetalVisualizerModel {
    const show = newModel.productConfiguration.productType === ProductType.ExtendedMetals && (newModel.productConfiguration.step === Step.pattern || newModel.productConfiguration.step === Step.design || newModel.productConfiguration.step === Step.cockpit);
    if ( !show ) {
      return null;
    }

    const viewModel: ExpandedMetalVisualizerModel = show ? {
      gltfId: getExpandedMetalGltfHash(newModel.productConfiguration),
      shape: newModel.drawing?.plate?.cutShapeWithHoles,
      patternGrids: newModel.drawing?.patternGrid,
      hexColorString: newModel.drawing?.plate?.hexColorString || '#B0B0B0',
      toolOffset: newModel.drawing?.tool?.name === 'ExpandedMetalTool' ? (newModel.drawing?.tool as ExpandedMetalModeToolModel).offset : null,
      toleranceLength: newModel.drawing?.toleranceLength || 0,
      toleranceWidth: newModel.drawing?.toleranceWidth || 0,
    } : null;

    // not loaded yet
    if ( !newModel.gltfs[viewModel.gltfId] ) {
      return null;
    }

    if ( this.viewModel.gltfId !== viewModel.gltfId || this.viewModel.shape !== viewModel.shape || this.viewModel.hexColorString !== viewModel.hexColorString || this.viewModel.patternGrids !== viewModel.patternGrids || this.viewModel.toolOffset !== viewModel.toolOffset) {
      this.viewModel = viewModel;
    }
    return this.viewModel;
  }
}
