import libtess from 'libtess';
import {
  addVectors2,
  getEmptyAaabb, linesIntersection,
  ModelVisualizer, multiplyVector2byScalar,
  normalizeVector2,
  PolylinesToVector2Arrays,
  subVectors2,
  Vector2,
  Webcad
} from 'webcad';
import {
  Node,
  Mesh,
  Effect,
  ShaderMaterial,
  VertexBuffer,
  Buffer,
  BoundingInfo,
  SubMesh,
  AbstractMesh,
  Engine,
  StencilState,
  VertexData,
  Vector3 as B_Vector3,
  Vector4 as B_Vector4,
  Nullable,
  Scene
} from '@babylonjs/core';
import {ShapeWithHoles} from '../model/shape-with-holes';
import {splitToRegions, Tesselator} from '../utils/bending';
import {hexToRgb} from '../utils/utils';
import {PatternGrid} from '../model/product-configuration/element.model';

export interface ExpandedMetalVisualizerModel {
  gltfId: string;
  // type: string;
  shape: ShapeWithHoles;
  patternGrids: PatternGrid[];
  toolOffset: Vector2;
  hexColorString: string;
  toleranceWidth: number;
  toleranceLength: number;

}
export class ExpandedMetalVisualizer implements ModelVisualizer<ExpandedMetalVisualizerModel> {
  private model: ExpandedMetalVisualizerModel;
  private material: ShaderMaterial;
  private mesh: ExpandedMetal = null;


  constructor() {}
  protected parentNode: Node;

  init(rootNode: Node, model: ExpandedMetalVisualizerModel, webcad: Webcad): Promise<void> {
    this.model = model;
    this.parentNode = rootNode;
    createShaders();
    const scene = this.parentNode.getScene();
    this.material = new ShaderMaterial(
      'expmetal',
      scene,
      {
        vertex: 'expmetal',
        fragment: 'expmetal',
      },
      {
        attributes: ['position', 'instancePos', 'normal'],
        uniforms: ['worldViewProjection', 'worldView', 'world', 'exMetColor', 'outer', 'inner'],
      }
    );
    this.material.backFaceCulling = true;
    this.material.cullBackFaces = true;
    return Promise.resolve();
  }

  edgeBlendMesh(shapeWithHoles: ShapeWithHoles, maxZ: number, minZ: number, xBlend, yBlend): Mesh {
    const positions: number[] = [];
    const colors: number[] = [];
    const indices: number[] = [];

    const polyLines = [...shapeWithHoles.holes];
    polyLines.push(shapeWithHoles.conture);
    const shapes =  PolylinesToVector2Arrays(polyLines);

    let vCount = 0;
    for (let i = 0; i < shapes.length; i++) {
      const shape = shapes[i];
      for (let j = 0; j < shape.length; j++) {
        const p0 = shape[j];
        const p1 = shape[(j + 1) % shape.length];
        const p2 = shape[(j + 2) % shape.length];
        const p3 = shape[(j + 3) % shape.length];
        const v01 = normalizeVector2(subVectors2(p1, p0));
        const v12 = normalizeVector2(subVectors2(p2, p1));
        const v23 = normalizeVector2(subVectors2(p3, p2));

        const rv01 = {x: -v01.y, y: v01.x};
        const rv12 = {x: -v12.y, y: v12.x};
        const rv23 = {x: -v23.y, y: v23.x};


        const o0 = addVectors2(p0, {x: rv01.x * xBlend, y: rv01.y * yBlend});
        const o1 = addVectors2(p1, {x: rv12.x * xBlend, y: rv12.y * yBlend});
        const o2 = addVectors2(p2, {x: rv23.x * xBlend, y: rv23.y * yBlend});

        let np1 = linesIntersection(o0, o1, v01, v12);
        if (np1 === null) {
          np1 = o1;
        }
        let np2 = linesIntersection(o1, o2, v12, v23);
        if (np2 === null) {
          np2 = o2;
        }

        positions.push(p1.x , p1.y, minZ);  // 0
        positions.push(p2.x , p2.y, minZ);  // 1
        positions.push(np1.x, np1.y, minZ); // 2
        positions.push(np2.x, np2.y, minZ); // 3
        positions.push(p1.x , p1.y, maxZ);  // 4
        positions.push(p2.x , p2.y, maxZ);  // 5
        positions.push(np1.x, np1.y, maxZ); // 6
        positions.push(np2.x, np2.y, maxZ); // 7
        colors.push(1, 0, 0, -0.2);
        colors.push(1, 0, 0, -0.2);
        colors.push(1, 0, 0, 1);
        colors.push(1, 0, 0, 1);
        colors.push(1, 0, 0, -0.2);
        colors.push(1, 0, 0, -0.2);
        colors.push(1, 0, 0, 1);
        colors.push(1, 0, 0, 1);
        indices.push(vCount + 0, vCount + 1, vCount + 2, vCount + 1, vCount + 3, vCount + 2);
        indices.push(vCount + 4, vCount + 6, vCount + 5, vCount + 5, vCount + 6, vCount + 7);

        indices.push(vCount + 0, vCount + 4, vCount + 1, vCount + 1, vCount + 4, vCount + 5);

        vCount += 8;


        // positions.push(p1.x , p1.y, maxZ);
        // positions.push(p2.x , p2.y, maxZ);
        // positions.push(p2.x + cx.x * 0.0075, p2.y + cx.y * 0.015, maxZ);
        // positions.push(p1.x + cx.x * 0.0075, p1.y + cx.y * 0.015, maxZ);
        // colors.push(1, 0, 0, -0.2);
        // colors.push(1, 0, 0, -0.2);
        // colors.push(1, 0, 0, 1);
        // colors.push(1, 0, 0, 1);
        // indices.push(vCount + 0, vCount + 1, vCount + 2, vCount + 0, vCount + 2, vCount + 3);
        // vCount += 4;
      }
    }


    const mesh = new Mesh('edgeBlend');
    const vertexData = new VertexData();
    vertexData.positions = positions;
    vertexData.indices = indices;
    vertexData.colors = colors;
    vertexData.applyToMesh(mesh);
    return mesh;

    return;
  }

  volumeMesh(shapeWithHoles: ShapeWithHoles, maxZ: number, minZ: number): Mesh {
    const cutOutTess = new Tesselator(
      libtess.windingRule.GLU_TESS_WINDING_POSITIVE
    );
    const shapeContours: number[][] = [];
    cutOutTess.addPolyline(shapeWithHoles.conture, shapeContours);
    if (shapeWithHoles.holes) {
      cutOutTess.addPolylines(shapeWithHoles.holes, shapeContours);
    }
    const tris = cutOutTess.triangulate();
    const regions = splitToRegions(tris);
    const region = regions[0];
    if ( region ) {
      const positions: number[] = [];
      const indices: number[] = [];

      for (let i = 0; i < region.vertices.length; i++) {
        const vertex = region.vertices[i];
        positions.push(vertex.x, vertex.y, minZ);
      }
      for (let i = 0; i < region.vertices.length; i++) {
        const vertex = region.vertices[i];
        positions.push(vertex.x, vertex.y, maxZ);
      }

      const vo1 = 0;
      for (let i = 0; i < region.triangles.length; i += 3) {
        indices.push(vo1 + region.triangles[i]);
        indices.push(vo1 + region.triangles[i + 1]);
        indices.push(vo1 + region.triangles[i + 2]);
      }
      const vo2 = vo1 + region.vertices.length;
      for (let i = 0; i < region.triangles.length; i += 3) {
        indices.push(vo2 + region.triangles[i]);
        indices.push(vo2 + region.triangles[i + 2]);
        indices.push(vo2 + region.triangles[i + 1]);
      }

      let i1,
        i2,
        i3,
        i4;

      for (let i = 0; i < region.edges.length; i += 2) {
        i1 = vo1 + region.edges[i + 1];
        i2 = vo1 + region.edges[i];
        i3 = vo2 + region.edges[i + 1];
        i4 = vo2 + region.edges[i];
        // indices.push(1x, v1y, v1z, v2x, v2y, v2z, v3x, v3y, v3z, v4x, v4y, v4z, v3x, v3y, v3z, v2x, v2y, v2z);
        indices.push(i1, i2, i3, i4, i3, i2);
      }



      const mesh = new Mesh('expmet_volume');
      const vertexData = new VertexData();
      vertexData.positions = positions;
      vertexData.indices = indices;
      vertexData.applyToMesh(mesh);
      return mesh;

    }

  }
  updateVisualization(newModel: ExpandedMetalVisualizerModel): void {
    const scene = this.parentNode.getScene();
    if (this.model !== newModel) {
      if (!!newModel  && !!newModel.gltfId && !!newModel.shape && !!newModel.shape.conture && newModel.shape.conture.length > 0 && newModel.patternGrids?.length > 0) {


        if (
          !this.model || !this.model.shape || !this.model.shape.conture && this.model.shape.conture.length <= 0 || !this.model.patternGrids ||
          newModel.gltfId !== this.model?.gltfId || newModel.patternGrids.length !== this.model.patternGrids.length ||
          newModel.patternGrids.some(
            (npg, i) => npg.countX !== this.model.patternGrids[i].countX || npg.countY !== this.model.patternGrids[i].countY
          )
        ) {
          if (this.mesh) {
            this.mesh.dispose();
          }
          const gltfId = newModel.gltfId;
          const patternGrids = newModel.patternGrids;
          const mesh = (scene as any).gltfs[gltfId];
          const count = patternGrids.reduce((pv, nv) => pv + nv.countX * nv.countY, 0);
          const instancedMesh = new ExpandedMetal(
            'exp-met',
            count,
            scene
          );
          instancedMesh.setVerticesData(
            VertexBuffer.PositionKind,
            mesh.getVerticesData(VertexBuffer.PositionKind, false),
            false
          );
          instancedMesh.setVerticesData(
            VertexBuffer.NormalKind,
            mesh.getVerticesData(VertexBuffer.NormalKind, false),
            false
          );
          instancedMesh.setIndices(mesh.getIndices());
          const positions: Float32Array = new Float32Array(400 * 400 * 2);

          let pos = 0;
          for (const patternGrid of patternGrids) {
            const dx = patternGrid.startX - patternGrids[0].startX;
            const dy = patternGrid.startY - patternGrids[0].startY;
            for (let y = 0; y < patternGrid.countY; y++) {
              for (let x = 0; x < patternGrid.countX; x++) {
                positions[pos * 2] = x * patternGrid.deltaX + dx;
                positions[pos * 2 + 1] = y * patternGrid.deltaY + dy;
                pos++;
              }
            }
          }

          const instancesBuffer = new Buffer(
            scene.getEngine(),
            positions,
            false,
            2,
            false,
            true
          );
          (instancedMesh as any)._instancesBuffer = instancesBuffer;
          const positionsVertexBuffer = instancesBuffer.createVertexBuffer(
            'instancePos',
            0,
            2
          );
          instancedMesh.setVerticesBuffer(positionsVertexBuffer);
          instancedMesh.material = this.material;
          instancedMesh.setBoundingInfo(new BoundingInfo(
            new B_Vector3(-100, -100, -100),
            new B_Vector3(100, 100, 100)
          ));
          this.mesh = instancedMesh;
        }

        if (this.mesh) {
          if (newModel.toolOffset) {
            const shapeMin = newModel.shape.aabb.min;
            const offsetX = newModel.toolOffset.x;
            const offsetY = newModel.toolOffset.y;
            const swd = newModel.patternGrids[0].deltaX;
            const lwd = newModel.patternGrids[0].deltaY;
            const startX = offsetX - Math.ceil(((offsetX - swd / 2.0) - shapeMin.x) / swd) * swd - swd;
            const startY = offsetY - Math.ceil(((offsetY - lwd / 2.0) - shapeMin.y) / lwd) * lwd - lwd;
            this.mesh.position.set(startX, startY , 0);
          } else {
            this.mesh.position.set(newModel.patternGrids[0].startX, newModel.patternGrids[0].startY, 0);
          }

          const rgb = hexToRgb(newModel.hexColorString);
          const color = new B_Vector4(rgb.r / 255, rgb.g / 255, rgb.b / 255, 1);

          this.material.setVector4('exMetColor', color);

          // const toleranceWidth = Math.max(0.5, newModel.toleranceWidth) / 1000;
          // const toleranceLength = Math.max(0.5, newModel.toleranceLength) / 1000;
          // this.material.setVector4('inner', new B_Vector4(
          //   newModel.shape.aabb.min.x + toleranceLength,
          //   newModel.shape.aabb.min.y + toleranceWidth,
          //   newModel.shape.aabb.max.x - toleranceLength,
          //   newModel.shape.aabb.max.y - toleranceWidth,
          // ));
          // this.material.setVector4('outer', new B_Vector4(
          //   newModel.shape.aabb.min.x,
          //   newModel.shape.aabb.min.y,
          //   newModel.shape.aabb.max.x,
          //   newModel.shape.aabb.max.y
          // ));

          // this.material.setVector4('inner', new B_Vector4(
          //   0.1,
          //   0.1,
          //   0.9,
          //   0.9,
          // ));
          // this.material.setVector4('outer', new B_Vector4(
          //   0,
          //   0,
          //   1,
          //   1
          // ));


          if (newModel.shape !== this.model?.shape || !this.mesh?.volumeMesh) {
            if (this.mesh.volumeMesh) {
              this.mesh.volumeMesh.dispose();
            }

            const aabb = (this.parentNode.getScene() as any).gltfs[newModel.gltfId].getBoundingInfo();
            this.mesh.volumeMesh = this.volumeMesh(newModel.shape, aabb.boundingBox.maximum.z, aabb.boundingBox.minimum.z);
            const m = new ShaderMaterial(
              'expmetal',
              scene,
              {
                vertex: 'expmetal',
                fragment: 'expmetal',
              },
              {
                attributes: ['position', 'instancePos', 'normal'],
                uniforms: ['worldViewProjection', 'worldView', 'world', 'exMetColor'],
                needAlphaBlending: true,
              }
            );
            this.mesh.volumeMesh.material = m;

            if (this.mesh.edgeBlendMesh) {
              this.mesh.edgeBlendMesh.dispose();
            }
            // const patternGrids = newModel.patternGrids;
            // const dx = patternGrids[0].deltaX;
            // const dy = patternGrids[0].deltaY;

            this.mesh.edgeBlendMesh = this.edgeBlendMesh(newModel.shape, aabb.boundingBox.maximum.z, aabb.boundingBox.minimum.z, newModel.toleranceLength / 1000, newModel.toleranceWidth / 1000);
            this.mesh.edgeBlendMesh.material = new ShaderMaterial(
              'expmetalBlend',
              scene,
              {
                vertex: 'expmetalBlend',
                fragment: 'expmetalBlend',
              },
              {
                attributes: ['position', 'color'],
                uniforms: ['worldViewProjection'],
              }
            );

          }
          (this.mesh.volumeMesh?.material as ShaderMaterial)?.setVector4('exMetColor', color);
        }
      } else {
        this.dispose();
      }
    }
    this.model = newModel;
  }

  dispose(): void {
    if (this.mesh) {
      this.mesh.volumeMesh.dispose();
      this.mesh.dispose();
    }
    this.mesh = null;
  }

}

class ExpandedMetal extends Mesh {
  public count = 0;
  public volumeMesh: Mesh;
  public edgeBlendMesh: Mesh;
  constructor(name: string, count: number, scene?: Nullable<Scene>, parent?: Nullable<Node>, source?: Nullable<Mesh>, doNotCloneChildren?: boolean, clonePhysicsImpostor?: boolean) {
    super(name, scene, parent, source, doNotCloneChildren, clonePhysicsImpostor);
    this.count = count;
  }
  _checkOcclusionQuery() {
    this.isOccluded = false;
    return this.isOccluded;
  }

  renderMesh(mesh: Mesh, colorWrite: boolean , ccw: boolean, stencilState: StencilState,  instancesCount?: number) {
    const subMesh = mesh.subMeshes[0];
    const effect = mesh.material._getDrawWrapper().effect;
    const engine = mesh._scene.getEngine();
    const material = mesh.material as ShaderMaterial;
    let fillMode = material.fillMode;

    mesh._internalAbstractMeshDataInfo._isActive = false;
    if (!mesh._geometry || !mesh._geometry.getVertexBuffers() || (!mesh._unIndexed && !mesh._geometry.getIndexBuffer())) {
      return mesh;
    }

    // init material  (effect)
    if ( !mesh.material.isReady(mesh, false) ) {
      return mesh;
    }

    // bind
    engine.enableEffect(effect);
    engine.setState(true, 0, false, ccw, false, stencilState, 0);
    engine.setColorWrite(colorWrite);

    mesh._bind(subMesh, effect, fillMode, false);
    const world = mesh.getWorldMatrix();
    material.bind(world, mesh, effect);

    // draw
    fillMode = mesh._getRenderingFillMode(fillMode);
    mesh._draw(subMesh, fillMode, instancesCount);

    // unbid
    engine.unbindInstanceAttributes();
    material.unbind();

  }

  render(subMesh: SubMesh, enableAlphaMode: boolean, effectiveMeshReplacement?: AbstractMesh): Mesh {
    // return super.render(subMesh, enableAlphaMode, effectiveMeshReplacement);
    console.log('EXPMETAL RENDER');

    const engine = this._scene.getEngine();
    const gl = engine._gl;

    const blendSrcRgb = gl.getParameter(gl.BLEND_SRC_RGB);
    const blendDestRgb = gl.getParameter(gl.BLEND_DST_RGB);
    const blendSrcAlpha = gl.getParameter(gl.BLEND_SRC_ALPHA);
    const blendDestAlpha = gl.getParameter(gl.BLEND_DST_ALPHA);

    gl.enable(gl.BLEND);

    gl.blendFuncSeparate(gl.ZERO, gl.ONE, gl.ONE, gl.ZERO);
    this.renderMesh(this.edgeBlendMesh, true, true, null, undefined);
    gl.disable(gl.BLEND);

    this.renderMesh(this.volumeMesh, false, true, null, undefined);

    const ss = new StencilState();
    ss.enabled = true;
    ss.opStencilDepthPass = Engine.REPLACE;
    ss.mask = 0xFF;
    ss.func = Engine.ALWAYS;
    ss.funcRef = 1;
    this.renderMesh(this, false, false, ss, this.count);

    engine.clear(null, false, true, false);

    ss.enabled = false;
    this.renderMesh(this.volumeMesh, false, false, ss, undefined);

    ss.enabled = true;
    ss.mask = 0x00;
    ss.func = Engine.NOTEQUAL;
    ss.funcRef = 1;

    gl.enable(gl.BLEND);
    gl.blendFuncSeparate(gl.DST_ALPHA, gl.ONE_MINUS_DST_ALPHA, gl.ZERO, gl.ONE);
    this.renderMesh(this, true, false, ss, this.count);
    gl.disable(gl.BLEND);


    gl.blendFuncSeparate(blendSrcRgb, blendDestRgb, blendSrcAlpha, blendDestAlpha);

    gl.colorMask(false, false, false, true);
    engine.clear({r: 0, g: 0, b: 0, a: 1}, true, true, false);
    gl.colorMask(true, true, true, true);

    // engine.setDepthFunction(d);
    return this;

  }
}

function createShaders() {
  Effect.ShadersStore['expmetalVertexShader'] =
    `
    precision highp float;
    attribute vec3 position;
    attribute vec3 normal;
    attribute vec2 instancePos;
    uniform mat4 worldViewProjection;
    uniform mat4 worldView;
    uniform mat4 world;
    uniform vec4 exMetColor;
    varying vec4 vColor;
    uniform vec4 outer;// = vec4(0,0.3,1,1);
    uniform vec4 inner;// = vec4(0.1,0.4,0.9,0.9);

    void main(void) {

      vec3 n = vec3(worldView * vec4(normal, 0.0));
      float diffuse = max(dot(n, vec3(0.0 ,0.0 , -1.0)), 0.1);
      // vColor = vec4( vec3(1, 1, 1) * pow(diffuse, 0.5), 1.0);
      vColor = vec4( vec3(exMetColor) * pow(diffuse, 0.7), 1.0);
      // vColor = vec4( n, 1.0);
      vec3 pos = vec3(position.x,position.y,position.z) + vec3(instancePos.x, instancePos.y, 0);
      gl_Position = worldViewProjection * vec4(pos, 1.0);
    }
  `;
  Effect.ShadersStore['expmetalFragmentShader'] =
    `
    precision highp float;
    varying vec4 vColor;
    void main(void) {
      gl_FragColor = vColor;
    }
  `;

  Effect.ShadersStore['expmetalBlendVertexShader'] =
    `
    precision highp float;
    attribute vec3 position;
    attribute vec4 color;
    uniform mat4 worldViewProjection;
    varying vec4 vColor;

    void main(void) {
      gl_Position = worldViewProjection * vec4(position, 1.0);
      vColor = color;
    }
  `;
  Effect.ShadersStore['expmetalBlendFragmentShader'] =
    `
    precision highp float;
    varying vec4 vColor;
    void main(void) {
      gl_FragColor = vColor;
    }
  `;
}
