
export enum TemplateName {
  none= 'None',

  // SIMPLE
  basicRectangle = 'Basic Rectangle',
  rectangleNotch = 'Rectangle Notch',
  balconyCorner = 'Balcony Corner',
  multiShape = 'Multi Shape',
  parallelogram = 'Parallelogram',
  trapeze =  'Trapeze',
  staircase = 'Staircase',

  expandedMetalBasicRectangle = 'Expanded Metal Basic Rectangle',
  expandedMetalTrapeze = 'Expanded Metal Trapeze',
  expandedMetalParallelogram = 'Expanded Metal Parallelogram',

  // CASSETTES
  umschlag = 'Umschlag',
  singleBend = 'Single bend',
  twoBend = 'Two bend',
  korb = 'Korb',
  flat = 'Flat'

}
