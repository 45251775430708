<div class="dialog-modal-header">
  <div class="dialog-modal-header-content">
    <div class="dialog-modal-title-container">
      <h4 class="dialog-modal-title-primary" id="trapeze-title">
        {{ this.templateUtils.translate("Trapeze") }}
      </h4>
      <span class="dialog-modal-title-secondary"
      >{{
          this.templateUtils.translate("EnterDimenstions", "designer")
        }}
        (mm):</span
      >
    </div>
<!--    <div class="dialog-modal-window">-->
<!--      <span>{{-->
<!--          this.templateUtils.translate("SketchDoesntChange", "designer")-->
<!--        }}</span>-->
<!--    </div>-->
  </div>

  <button
    type="button"
    class="close btn-close"
    aria-label="Close"
    (click)="dismiss('Cross click'); isModalOpenMethod()"
    [tabIndex]="-1"
  ></button>
</div>

<div class="dialog-modal-body">
  <div class="trapeze-inputs">
    <input
      type="number"
      id="trapeze_a"
      class="input-small trapeze-a"
      [tabIndex]="1"
      (keydown)="this.templateUtils.returnToLastElement($event, trapeze_beta)"
      [ngStyle]="
        trapezeTemplate.a == null || trapezeTemplate.a < 0
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [value]="trapezeTemplate.a"
      (input)="setTrapezeA(this.templateUtils.roundInput($event, 0, 5000))"
      required
      #trapeze_a
    />

    <input
      type="number"
      id="trapeze_b1"
      class="input-small trapeze-b1"
      [tabIndex]="6"
      [ngStyle]="
        trapezeTemplate.b1 == null || trapezeTemplate.b1 < 0
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [value]="trapezeTemplate.b1"
      (input)="setTrapezeB1(this.templateUtils.roundInput($event, 0, 5000))"
      required
    />

    <input
      type="number"
      id="trapeze_b2"
      class="input-small trapeze-b2"
      [tabIndex]="4"
      [ngStyle]="
        trapezeTemplate.b2 == null || trapezeTemplate.b2 < 0
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [value]="trapezeTemplate.b2"
      (input)="setTrapezeB2(this.templateUtils.roundInput($event, 0, 5000))"
      required
    />

    <input
      type="number"
      id="trapeze_hb"
      class="input-small trapeze-hb"
      [tabIndex]="3"
      [ngStyle]="
        trapezeTemplate.hb == null
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [value]="trapezeTemplate.hb"
      (input)="setTrapezeHB(this.templateUtils.roundInput($event, 0, 5000))"
      required
    />

    <input
      type="number"
      id="trapeze_alpha"
      class="input-small trapeze-alpha"
      [tabIndex]="7"
      [ngStyle]="
        trapezeTemplate.alpha == null
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [value]="trapezeTemplate.alpha * (180 / pi) | number : '1.0-2'"
      (input)="
        setTrapezeAlpha(this.templateUtils.roundInput($event, null, null, 2))
      "
      required
    />

    <input
      type="number"
      id="trapeze_ha1"
      class="input-small trapeze-ha1"
      [tabIndex]="2"
      [ngStyle]="
        trapezeTemplate.ha1 == null || trapezeTemplate.ha1 < 0
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [value]="trapezeTemplate.ha1"
      (input)="setTrapezeHA1(this.templateUtils.roundInput($event, 0, 5000))"
      required
    />

    <input
      type="number"
      id="trapeze_beta"
      class="input-small trapeze-beta"
      [tabIndex]="9"
      [ngStyle]="
        trapezeTemplate.beta == null
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [value]="trapezeTemplate.beta * (180 / pi) | number : '1.0-2'"
      (input)="
        setTrapezeBeta(this.templateUtils.roundInput($event, null, null, 2))
      "
      required
      #trapeze_beta
    />

    <input
      type="number"
      id="trapeze_ha2"
      class="input-small trapeze-ha2"
      [tabIndex]="5"
      [ngStyle]="
        trapezeTemplate.ha2 == null || trapezeTemplate.ha2 < 0
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [value]="trapezeTemplate.ha2"
      (input)="setTrapezeHA2(this.templateUtils.roundInput($event, 0, 5000))"
      required
    />
  </div>
</div>
<div class="dialog-modal-footer">
  <span class="dialog-modal-link" (click)="this.templateUtils.setDefault()">
    {{ this.templateUtils.translate("Reset dimensions to default.") }}
  </span>
  <button
    #closeButton
    type="button"
    class="btn btn-primary btn-create"
    [tabIndex]="1"
    [disabled]="reqValTrapeze | async"
    (click)="close(trapezeTemplate)"
  >
    {{ this.templateUtils.translate("Create") }}
  </button>
</div>
